<template>
	<validation-observer
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Details
			</template>
			<template #body>
				<div class="d-flex flex-row cassie-horizontal-sm">
					<TextField
						:value="name"
						label="Name *"
						:rules="{ required: true, max: 50}"
						class="cassie-input-width-md"
						tooltip-text="Enter a suitable name for this integration service"
						:disabled="!userFullPermissions"
						@input="$emit('update:name', $event)"
					/>
					<Dropdown
						:label="'Brand*' | useLabels"
						:value="(singleBrand ? defaultBrandInSelect : !clientMultiBranded ? 0 : brandId)"
						:items="filteredBrandOptions"
						custom-sort
						searchable
						:rules="{ required: clientMultiBranded}"
						class="cassie-input-width-md"
						tooltip-text="Select a specific brand name for this integration service"
						:disabled="!userFullPermissions || !clientMultiBranded || singleBrand"
						@input="$emit('update:brandId', $event)"
					/>
				</div>
				<Textarea
					:value="notes"
					label="Description"
					tooltip-text="Enter any notes to help describe this integration service (internal use only)"
					:disabled="!userFullPermissions"
					@input="$emit('update:notes', $event)"
				/>
				<Dropdown
					label="System Integration *"
					:value="integrationId"
					:items="systemIntegrationItems"
					searchable
					rules="required"
					class="cassie-input-width-md"
					tooltip-text="Choose the integration system that this service is a part of"
					:disabled="!userFullPermissions"
					@input="$emit('update:integrationId', $event)"
				/>
			</template>
		</SectionCard>
	</validation-observer>
</template>
<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../../shared/components/textarea.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions, UNIVERSAL_BRAND, UNIVERSAL_BRAND_ITEM } from '../../../../../../shared/state/brands.js'

export default {
	components: { Dropdown, TextField, Textarea, SectionCard },
	props: {
		systemIntegrationItems: Array,
		name: String,
		brandId: Number,
		isEdit: Boolean,
		notes: String,
		integrationId: Number,
		userFullPermissions: Boolean
	},
	setup () {
		return {
			brandOptions,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		filteredBrandOptions () {
			const containsUniversal = brandOptions.value.map(brand => {
				return brand.value
			}).includes(UNIVERSAL_BRAND)

			if (containsUniversal || !this.isEdit || this.cloneMode) {
				return brandOptions.value
			} else {
				return [
					UNIVERSAL_BRAND_ITEM,
					...brandOptions.value
				]
			}
		}
	}
}
</script>
